import * as React from 'react';
import { RouteProps, Route, BrowserRouter as Router } from 'react-router-dom';
import { IGuidedPhotoCaptureShell, IGuidedPhotoCaptureNavigationProps } from '../interfaces';
import { Store } from '../store';
import { Provider } from 'react-redux';
/**
 * Renders home page or application shell.
 * @returns Returns the shell
 */
type RoutePageProps = { id: string } & RouteProps;
const RoutePage: React.FC<RoutePageProps> = (props: RoutePageProps) => <Route {...props} />;
const GuidedPhotoCaptureNavigation: React.FC<IGuidedPhotoCaptureNavigationProps> = (
	props: IGuidedPhotoCaptureNavigationProps
): JSX.Element => {
	return <RoutePage id={'mi-guidedPhotoCapture_route_Home'}>{props.homePage}</RoutePage>;
};
export const GuidedPhotoCaptureShell: React.FC<IGuidedPhotoCaptureShell> = (
	props: IGuidedPhotoCaptureShell
): React.ReactElement => {
	return (
		<Provider store={Store}>
			<Router basename={'/'}>
				<GuidedPhotoCaptureNavigation homePage={props.homePage} />
			</Router>
		</Provider>
	);
};
