import Backend from 'i18next-xhr-backend';
import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import './numbro';
import numbro from 'numbro';
import translationEN from '@mitchell/redux.module.apd/dist/i18n/en.json';
import translationFR from '@mitchell/redux.module.apd/dist/i18n/fr.json';
import translationES from '@mitchell/redux.module.apd/dist/i18n/es.json';

const overrideLanguageCode = null; // set to 'en', 'fr', or whatever for testing. set to null to allow normal browser detection.
const languages = ['en', 'fr', 'es', 'en-US', 'fr-CA']; // set to any languages you want to support AND have translation files for.
numbro.setLanguage('en-US');

function isNumber(n) {
	return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export const i18nInterpolation = {
	escapeValue: false,
	formatSeparator: ',',
	format: function (value, format) {
		if (isNumber(value)) return getFormattedCurrency(value, format);
		return value;
	},
};

function getFormattedCurrency(value, format) {
	switch (format) {
		case 'NetTotal':
			return formatKey(value);
		default:
			return numbro(value).formatCurrency();
	}
}

function formatKey(value) {
	switch (numbro.language()) {
		case 'fr-CA':
			return numbro(value).formatCurrency({
				currencySymbol: '$ CA',
				spaceSeparated: true,
				thousandSeparated: true,
				mantissa: 2,
			});
		case 'en-CA':
			return numbro(value).formatCurrency({
				currencySymbol: 'Can$',
				thousandSeparated: true,
				mantissa: 2,
			});
		default:
			return numbro(value).formatCurrency();
	}
}

const i18nconfig = {
	ns: ['translations'],
	defaultNS: 'translations',
	fallbackLng: 'en',
	keySeparator: '>',
	nsSeparator: '|',
	resources: {
		en: {
			translations: {
				...translationEN,
			},
		},
		es: {
			translations: {
				...translationES,
			},
		},
		'es-ES': {
			translations: {
				...translationES,
			},
		},
		'en-US': {
			translations: {
				...translationEN,
			},
		},
		fr: {
			translations: {
				...translationFR,
			},
		},
		'fr-CA': {
			translations: {
				...translationFR,
			},
		},
		'fr-FR': {
			translations: {
				...translationFR,
			},
		},
	},
	partialBundledLanguages: true,
	backend: {
		loadPath: `/i18n/{{lng}}.json`,
		addPath: `/i18n/{{lng}}`,
	},
	detection: {
		caches: null,
	},
	debug: true,
	interpolation: i18nInterpolation,
	react: {
		wait: true,
		withRef: false,
		interpolation: {
			escapeValue: false, // not needed for react!
		},
		bindI18n: 'languageChanged',
		bindStore: false,
		nsMode: 'default',
	},
};

if (overrideLanguageCode) i18nconfig.lng = overrideLanguageCode;

const i18nInstance = i18next.use(Backend).use(LanguageDetector).use(reactI18nextModule).init(i18nconfig);

export { i18nInstance, languages };

export default i18nInstance;