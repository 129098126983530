import * as React from 'react';
import { TranslationService } from '@mitchell/redux.module.apd/dist/domain/common/services/TranslationService';
import { ConnectedGPCRootComponent } from '@mitchell/redux.module.apd/dist/app/guidedPhotoCapture/containers';
import { IGPCRootComponent } from '@mitchell/redux.module.apd/dist/app/guidedPhotoCapture/interfaces';
import { GuidedPhotoCaptureShell } from './components';
import { createBrowserHistory } from 'history';

const App = (): React.ReactElement => {
	const urlParams = new URLSearchParams(window.location.search);
	const coCode = urlParams.get('coCd') || localStorage.getItem('coCd') || '';
	const contextId = urlParams.get('id') || localStorage.getItem('id') || '';
	const language = urlParams.get('lang') || localStorage.getItem('lang') || '';
	// set coCd,id and lang in localStorage

	const appProps: IGPCRootComponent = {
		companyCode: coCode,
		contextId: contextId,
		language: language,
		history: createBrowserHistory(),
	};

	localStorage.setItem('coCd', appProps.companyCode || '');
	localStorage.setItem('id', appProps.contextId || '');
	localStorage.setItem('lang', appProps.language || '');
	TranslationService.setCurrentLanguage(appProps.language);
	const renderComponent = React.useCallback(() => <ConnectedGPCRootComponent {...appProps} />, [appProps]);
	return <GuidedPhotoCaptureShell homePage={renderComponent} />;
};

export default App;
